import React         from 'react';
import styled        from '@emotion/styled';

const StyledSection = styled.div`
  margin-bottom: 50px;
  h3 {
    font-weight: bold;
    line-height: 1.8rem;
    text-transform: uppercase;
    span {
      color: ${props => props.theme.brand_color}
    }
  }
  .int-separator-line {
    height: 2px;
    background-color: ${props => props.theme.brand_color};
    margin-bottom: 50px;
  }
  h4 {
    font-weight: bold;
  }
`;

const Section = ({ children, heading1, heading2 }) => {
  return (
    <StyledSection>
      <h3>{heading1} <br /><span>{heading2}</span></h3>
      <div className={'int-separator-line'} />
      <div>{children}</div>
    </StyledSection>
  )
};

export default Section;