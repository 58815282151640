import React         from 'react';
import { graphql }   from 'gatsby';
import Img           from 'gatsby-image';
import { Flex, Box } from '@chakra-ui/react';

import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Separator     from '@interness/web-core/src/components/structure/Separator/Separator';
import HeaderImage   from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import { findMedia } from '@interness/web-core/src/components/_helpers';
import CTASection    from '@interness/web-core/src/components/modules/CTASection/CTASection';

import Section           from '../components/Section';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';

function IndexPage(props) {
  return (
    <>
      <HeaderImage>
        <Img fluid={findMedia(props.data.homeImages.images, 'header-1').childImageSharp.fluid}/>
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <Heading subtitle={'Herzlich Willkommen'}>Physiotherapie Susanne Reiermann</Heading>
        <section>
          <p>Herzlich willkommen in der Praxis für Physiotherapie Susanne Reiermann in Schwelm.</p>

          <p>Ganzheitlichkeit ist meine Philosophie bei der Behandlung Ihrer Schmerzzustände, Blockaden oder
            Funktionsstörungen. Nach Verletzungen und Operationen kann ich Ihnen helfen, wieder in den Alltag zurück zu
            finden und Ihre persönliche Leistungsfähigkeit zu steigern.
            Bei all dem ist mir ein individueller und ganzheitlicher Therapieansatz wichtig. Eine Balance zwischen
            Kraft, Ausdauer und Beweglichkeit ist die Grundlage für Ihr Wohlbefinden und Ihre Belastbarkeit. Manuelle
            Therapie, die Behandlung von Craniomandibulären Dysfunktionen, KG-ZNS auf der Basis von Propriozeptiver
            Neuromuskulärer Fazilitation, Craniosacrale sowie Viszerale Therapie sind nur ein Teil meiner Therapien.
            Erfahren Sie mehr auf meiner Seite Therapieangebote. Sie als Mensch stehen bei mir im Mittelpunkt.</p>

          <p>Ich freue mich auf Ihren Besuch in meiner Praxis.<br/>
            Ihre Susanne Reiermann</p>
        </section>
        <LiveAnnouncements/>
        <Spacer/>
        <Flex justifyContent={'space-between'} flexWrap={'wrap'}>
          <Box width={['100%', '45%', '45%', '45%']}>
            <Section heading1={'Susanne'} heading2={'Reiermann'}>
              <Img fluid={findMedia(props.data.homeImages.images, 'portrait').childImageSharp.fluid}/>
            </Section>
          </Box>
          <Box width={['100%', '45%', '45%', '45%']}>
            <Section heading1={'Beruflicher'} heading2={'Werdegang'}>
              <h4>Ausbildung ab 1991</h4>
              <ul>
                <li>Abitur</li>
                <li>Ausbildung zur Gymnastiklehrerin an der Dortmunder Berufsfachschule für Gymnastik, Abschluss:
                  Staatlich anerkannte Gymnastiklehrerin
                </li>
                <li>Ausbildung zur Physiotherapeutin an der Schule für Physiotherapie der St.-Elisabeth-Stiftung in
                  Bochum, Abschluss: Staatlich anerkannte Physiotherapeutin
                </li>
              </ul>
            </Section>
          </Box>
          <Box width={['100%', '45%', '45%', '45%']}>
            <h4 style={{fontWeight: 'bold'}}>Beruflicher Werdegang ab 1998</h4>
            <ul>
              <li>Therapiezentrum „Am Rathaus“, Radevormwald</li>
              <li>Rehabilitationszentrum „Thera-fit“ am Katholischen Krankenhaus Dortmund-West</li>
              <li>Sportmedizinisches Zentrum Bochum</li>
              <li>Seit 2006 selbständig in eigener Praxis in Schwelm</li>
              <li>Weiterbildungen</li>
            </ul>
          </Box>
          <Box width={['100%', '45%', '45%', '45%']}>
            <h4 style={{fontWeight: 'bold'}}>Manuelle Therapie</h4>
            <ul>
              <li>Propriozeptive Neuromuskuläre Fazilitation (PNF)</li>
              <li>Behandlung von Kiefergelenksbeschwerden (CMD)</li>
              <li>Lokale Stabilität nach Hamilton</li>
              <li>Dreidimensionale Skoliosetherapie nach Schroth</li>
              <li>Sektoraler Heilpraktiker für Physiotherapie</li>
            </ul>
          </Box>
        </Flex>
        <Spacer/>
        <Separator/>
        <CTASection
          header={'<span style="font-weight:bold;font-size: 2rem;line-height: 3rem;">Haben Sie Fragen? Rufen Sie uns an <br /> 02336 4759368 <br /> oder schreiben uns eine E-Mail</span>'}
          text={''}
          mailButtonText={'Kontaktformular'}
          showPhone={false}
          showAddress={false}
        />
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
  query {
    homeImages: directusMediaCollection(name: {eq: "index-images"}) {
      name
      images: media {
        file {
          localFile {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;